import React from "react";
import './title.scss';

export class Title extends React.Component {
    render() {
        return (
            <div className="app-title">Casper SSI Frameworkd</div>
        );
    }
}
